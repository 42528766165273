<template>
  <div class="register-left-section">
    <div class="iframe-wrapper">
      <myfs-dynamic-iframe title="registration" :d-src="iframeSource" />
    </div>
  </div>
</template>
<script>
import iframeSource from '@/mixins/iframeSource'
import MyfsDynamicIframe from '@/components/DynamicIframe.vue'
export default {
  props: {
    registrationUrl: {
      type: String,
      required: true
    }
  },
  mixins: [iframeSource],
  components: {
    MyfsDynamicIframe
  },
  computed: {
    iframeSource() {
      return this.alterIframeUrlByParams(
        this.$props.registrationUrl,
        'registration'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.register-left-section {
  background: #ffffff;
  height: 100%;

  .iframe-wrapper {
    padding: 0 9px;
    line-height: 0;

    iframe {
      width: 100%;
      height: 100%;
      min-height: 782px;
    }
  }
}
</style>
