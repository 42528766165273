<template>
  <base-view>
    <template #top>
      <the-header no-shadow is-even-wider />
      <the-title-section :title="title" is-padded is-mobile-centered />
      <one-col-box is-even-wider v-if="invalidRegistration" class="error">
        <h2>{{ $t('registration.hl_create_account') }}</h2>
        <p>
          <b>{{ $t('registration.hl_registrationerror') }}</b>
        </p>
        <p v-html="invalidRegistrationMessage"></p>
        <div v-if="invalidCouponCode">
          <base-button-link-native class="primary" :href="promotionUrl">
            {{ $t('registration.btn_try_again') }}
          </base-button-link-native>
        </div>
      </one-col-box>
      <two-col-box is-even-wider v-else>
        <template #col1>
          <the-register-left-section :registration-url="registrationUrl" />
        </template>
        <template #col2>
          <the-register-right-section
            :custom-html="rightSectionCustomHtml"
            :custom-top-html="rightSectionTopHtml"
            :coupon-registration="couponRegistration"
          />
        </template>
      </two-col-box>
    </template>
    <template #bottom>
      <the-region-picker />
      <the-help />
      <the-footer is-visible-on-mobile />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheTitleSection from '@/components/TheTitleSection.vue'
import TwoColBox from '@/components/TwoColBox.vue'
import TheRegisterLeftSection from '@/components/TheRegisterLeftSection.vue'
import TheRegisterRightSection from '@/components/TheRegisterRightSection.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import SegmentService from '@/services/SegmentService'
import AffiliateService from '@/services/AffiliateService'
import RegistrationService from '@/services/RegistrationService'
import OneColBox from '@/components/OneColBox.vue'
import BaseButtonLinkNative from '@/components/BaseButtonLinkNative.vue'
import { mapState } from 'vuex'
import XmlLocalizeService from '@/services/XmlLocalizeService'

const errorTranslations = {
  promotion_not_found: 'registration.error_link_not_valid',
  promotion_not_allowed: 'registration.error_no_access',
  promotion_not_allowed_in_location: 'registration.error_location_not_allowed',
  customer_already_activated: 'registration.sl_activate_link_invalid',
  promotion_expired: 'registration.error_expiredpromotion',
  invalid_coupon: 'registration.error_invalid_coupon',
  coupon_not_allowed: 'registration.error_code_not_available',
  voucher_used_up: 'registration.error_couponalreadyused',
  direct_purchase_id_invalid: 'registration.sl_activate_link_invalid'
}

export default {
  data() {
    return {
      rightSectionCustomLocalization: {},
      rightSectionTopCustomLocalization: {},
      couponRegistration: false,
      invalidRegistration: false,
      invalidRegistrationReason: '',
      affiliateName: '',
      freeMonths: '',
      registrationUrl: process.env.VUE_APP_REGISTER_IFRAME_URL
    }
  },
  components: {
    TheHeader,
    TheTitleSection,
    TwoColBox,
    TheRegisterLeftSection,
    TheRegisterRightSection,
    TheRegionPicker,
    TheHelp,
    TheFooter,
    BaseView,
    OneColBox,
    BaseButtonLinkNative
  },
  computed: {
    ...mapState({
      currentLocale: (state) => state.ui.currentLocale
    }),
    title() {
      return this.$t('registration.hl_fsecure_service')
    },
    invalidRegistrationMessage() {
      let reason = this.invalidRegistrationReason
      let linkstart = '<a href="/login">'
      let linkend = '</a>'
      return this.$t(errorTranslations[reason], { linkstart, linkend }) || ''
    },
    invalidCouponCode() {
      return this.invalidRegistrationReason === 'invalid_coupon'
    },
    promotionUrl() {
      var currentUrl = window.location.pathname
      let newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'))
      return newUrl
    },
    rightSectionCustomHtml() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.rightSectionCustomLocalization,
        this.currentLocale,
        {
          affiliate: (this.affiliateName || '').toUpperCase(),
          free_months: this.freeMonths || ''
        }
      )
    },
    rightSectionTopHtml() {
      return XmlLocalizeService.getTranlationText.bind(this)(
        this.rightSectionTopCustomLocalization,
        this.currentLocale,
        {}
      )
    }
  },
  deactivated() {
    this.rightSectionCustomLocalization = {}
    this.rightSectionTopCustomLocalization = {}
    this.couponRegistration = false
    this.invalidRegistration = false
    this.invalidRegistrationReason = ''
  },
  beforeRouteEnter(to, from, next) {
    let customizationResponseData = null
    let registationUrlData = null

    const onNext = (vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('register-view-entered', to.path, from.path)
      if (registationUrlData && registationUrlData.url) {
        vm.registrationUrl = registationUrlData.url.replace(
          'redirectUrlId%255D%3Dmyaccount_home',
          'redirectUrlId%255D%3Dredirect_to_frontend'
        )
      }
      vm.couponRegistration = registationUrlData.requiresCoupon || false
      if (customizationResponseData) {
        if (
          registationUrlData &&
          registationUrlData.errorCode === 'promotion_expired'
        ) {
          return
        }
        let freeMonths =
          customizationResponseData.documentElement.querySelector('freeMonths')
        if (freeMonths) {
          vm.freeMonths = freeMonths.textContent
        }
        let logo =
          customizationResponseData.documentElement.querySelector('logo')
        if (logo) {
          vm.$store.commit('ui/SET_LOGO_PATH', logo.textContent)
          vm.$store.commit(
            'ui/SET_LOGO_HEIGHT',
            (
              customizationResponseData.documentElement.querySelector(
                'logoHeight'
              ) || {}
            ).textContent
          )
          vm.$store.commit(
            'ui/SET_LOGO_WIDTH',
            (
              customizationResponseData.documentElement.querySelector(
                'logoWidth'
              ) || {}
            ).textContent
          )
        }
        if (registationUrlData && registationUrlData.affiliate) {
          vm.$store.commit('ui/SET_BRAND', registationUrlData.affiliate)
        } else {
          vm.$store.commit('ui/SET_BRAND', promotion)
        }
        const affiliateName = XmlLocalizeService.getValue(
          'affiliateName',
          customizationResponseData
        )

        if (affiliateName) {
          vm.affiliateName = affiliateName
        }

        const customRightElemName = registationUrlData.requiresCoupon
          ? 'registerRightSectionCouponContent'
          : 'registerRightSectionContent'
        const customRightLocalizedData = XmlLocalizeService.getLocalizations(
          customizationResponseData,
          customRightElemName
        )

        vm.rightSectionCustomLocalization = customRightLocalizedData

        const customRightTopLocalizedData = XmlLocalizeService.getLocalizations(
          customizationResponseData,
          'registerRightSectionTopContent'
        )

        vm.rightSectionTopCustomLocalization = customRightTopLocalizedData
      }
    }

    const directPurchaseHash = to.path.match('/activate/direct-purchase/')
      ? to.path.replace('/activate/direct-purchase/', '')
      : ''

    if (directPurchaseHash) {
      let params = {
        directPurchaseId: directPurchaseHash
      }
      RegistrationService.fetchRegistrationUrlData(params).then((response) => {
        registationUrlData = response
        if (!registationUrlData || registationUrlData.errorCode) {
          next((vm) => {
            vm.invalidRegistration = true
            vm.invalidRegistrationReason = registationUrlData.errorCode
          })
        }
        const affiliate = (registationUrlData.affiliate || '').toLowerCase()
        if (affiliate) {
          AffiliateService.fetchAffiliateData(affiliate)
            .then((data) => {
              customizationResponseData = data
              next(onNext)
            })
            .catch((err) => {
              if (err) {
                next('/register')
              }
            })
        } else {
          next(onNext)
        }
      })
      return
    }

    let promotion = (to.params.promotion || '').toLowerCase()
    const hash = to.query.hash

    if (!promotion && !hash) {
      next((vm) => {
        vm.$root.$emit('route-entered', to.path, from.path)
        vm.$root.$emit('register-view-entered', to.path, from.path)
      })
      return
    }

    if (!promotion) {
      promotion = 'f-secure-default'
    }

    const couponCode = to.params.voucher
    const firstname = to.query.firstname
    const lastname = to.query.lastname
    const uniqueid = to.query.uniqueid
    const email = to.query.email
    const xat = to.query['x-at']
    const countryid = to.query.countryid
    const ccruuid = to.query['ccr_app']
    let registrationParams = {
      promotionKey: promotion,
      couponCode,
      firstname,
      lastname,
      uniqueid,
      'x-at': xat,
      hash,
      countryid,
      email,
      ccruuid: ccruuid
    }
    Promise.all([
      RegistrationService.fetchRegistrationUrlData(registrationParams),
      SegmentService.fetchSegmentData(promotion)
    ])
      .then((values) => {
        registationUrlData = values[0]
        if (!registationUrlData || registationUrlData.errorCode) {
          next((vm) => {
            vm.invalidRegistration = true
            vm.invalidRegistrationReason = registationUrlData.errorCode
          })
        }
        const affiliate = (values[0].affiliate || '').toLowerCase()
        if (affiliate && !values[1]) {
          AffiliateService.fetchAffiliateData(affiliate)
            .then((data) => {
              customizationResponseData = data
              next(onNext)
            })
            .catch((err) => {
              if (err) {
                next('/register')
              }
            })
        } else {
          customizationResponseData = values[1]
          next(onNext)
        }
      })
      .catch((err) => {
        if (err) {
          next('/register')
        }
      })
  }
}
</script>

<style lang="scss" scoped>
.one-col-box.error {
  background-color: #f5f5f5;
  min-height: 200px;

  h2 {
    font-size: 1.87rem;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 40px;
    line-height: 1.21886em;
    font-weight: normal;
    color: $font-color;
  }

  p {
    margin: 0;
    padding-bottom: 20px;
    color: rgb(102, 102, 102);

    &:last-child {
      padding-bottom: 40px;
    }
  }

  div {
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) {
  .flex-btn {
    width: auto;
  }
}
</style>
