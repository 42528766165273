var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "register-left-section" }, [
    _c(
      "div",
      { staticClass: "iframe-wrapper" },
      [
        _c("myfs-dynamic-iframe", {
          attrs: { title: "registration", "d-src": _vm.iframeSource },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }