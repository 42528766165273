var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", {
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c("the-header", {
              attrs: { "no-shadow": "", "is-even-wider": "" },
            }),
            _c("the-title-section", {
              attrs: {
                title: _vm.title,
                "is-padded": "",
                "is-mobile-centered": "",
              },
            }),
            _vm.invalidRegistration
              ? _c(
                  "one-col-box",
                  { staticClass: "error", attrs: { "is-even-wider": "" } },
                  [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("registration.hl_create_account"))),
                    ]),
                    _c("p", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("registration.hl_registrationerror"))
                        ),
                      ]),
                    ]),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.invalidRegistrationMessage),
                      },
                    }),
                    _vm.invalidCouponCode
                      ? _c(
                          "div",
                          [
                            _c(
                              "base-button-link-native",
                              {
                                staticClass: "primary",
                                attrs: { href: _vm.promotionUrl },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("registration.btn_try_again")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _c("two-col-box", {
                  attrs: { "is-even-wider": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "col1",
                      fn: function () {
                        return [
                          _c("the-register-left-section", {
                            attrs: { "registration-url": _vm.registrationUrl },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "col2",
                      fn: function () {
                        return [
                          _c("the-register-right-section", {
                            attrs: {
                              "custom-html": _vm.rightSectionCustomHtml,
                              "custom-top-html": _vm.rightSectionTopHtml,
                              "coupon-registration": _vm.couponRegistration,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
          ]
        },
        proxy: true,
      },
      {
        key: "bottom",
        fn: function () {
          return [
            _c("the-region-picker"),
            _c("the-help"),
            _c("the-footer", { attrs: { "is-visible-on-mobile": "" } }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }